<template>
  <a-layout>
    <div class="logo"><img src="../assets/title.jpg"></div>
    <a-layout-header>
      <a-menu
        v-model:selectedKeys="selectedKeys1"
        mode="horizontal"
        @select="selectTitle"
      >
        <a-menu-item :key="menusList[0].items[0].key">
          <!--          <icon :type="menusList[0].icon" />-->
          <span>{{ menusList[0].name }}</span>
        </a-menu-item>
        <a-menu-item :key="menusList[1].items[0].key">
          <!--          <icon :type="menusList[1].icon" />-->
          <span>{{ menusList[1].name }}</span>
        </a-menu-item>
        <a-menu-item :key="menusList[2].items[0].key">
          <span>{{ menusList[2].name }}</span>
        </a-menu-item>
        <a-sub-menu key="sub">
          <template #title>
            <!--            <icon :type="menusList[2].icon" />-->
            <span>{{ menusList[3].name }}</span>
          </template>
          <a-menu-item v-for="item in menusList[3].items" :key="item.key">
            {{ item.title }}
          </a-menu-item>
        </a-sub-menu>
      </a-menu>

      <a-input-search
        placeholder="input search text"
        enter-button="Search"
        size="large"
        @search="onSearch"
      />
    </a-layout-header>
    <div class="breadcrum">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item v-for="item in breadList">{{ item }}</a-breadcrumb-item>
        <!--        <a-breadcrumb-item>Application Center</a-breadcrumb-item>-->
        <!--        <a-breadcrumb-item>Application List</a-breadcrumb-item>-->
        <!--        <a-breadcrumb-item>An Application</a-breadcrumb-item>-->
      </a-breadcrumb>

    </div>
    <a-layout-content class="content">
      <router-view v-slot="{ Component,route  }">
        <keep-alive>
          <component :inputKey="inputKey" :is="Component" :key="route.fullPath" @getBread="getBread" :breadList="breadList" />
        </keep-alive>
      </router-view>
    </a-layout-content>
    <div class="footer">
      <div class="f1">Copyright ©2023 Jazzphy. All rights reserved.
        <a href="http://beian.miit.gov.cn/" target="_blank" style="color: #fff">沪ICP备2024077693号</a>
      </div>
    </div>
  </a-layout>



</template>
<script setup lang="ts">
import { routes } from "@/router";
import { ref, computed, watch } from "vue";
import { RouteRecordRaw, useRoute, useRouter } from "vue-router";
const selectedKeys1 = ref<string[]>(["/layout/home"]);
const route = useRoute();
const router = useRouter();
const breadList = ref([]);
const inputKey = ref<string>("");

watch(() => route.fullPath, (ne, ol) => {
  selectedKeys1.value = [route.fullPath];
  breadList.value = [route.meta.title];
}, { immediate: true });
/** 将路由转换成菜单列表 */
const transformRouteToList = (routes: RouteRecordRaw[]) => {
  if (routes && routes.length === 0) return [];
  let res = [];
  routes.map(v => {
    let arr = [];
    v.children.map(x => {
      arr.push({ title: x.meta.title, key: v.path + "/" + x.path });
    });
    res.push({
      name: v.meta.title,
      icon: v.meta.icon,
      items: arr
    });
  });
  return res;
};

const menusList = computed(() => transformRouteToList(routes.slice(1)));
//选择一级标题时，自动跳转到对应的第一个router
const selectTitle = ({ item, key, selectedKeys }) => {
  router.push({ path: key });
  setTimeout(() => {
    if (route.meta.title.indexOf("About") != -1 || route.meta.title.indexOf("Quality") != -1) {
      breadList.value = ["About Us", route.meta.title];
    } else {
      breadList.value = [route.meta.title];
    }
  }, 500);

};
const getBread = (data) => {
  breadList.value = [route.meta.title];
  breadList.value = breadList.value.concat(data);
};
const onSearch = (searchValue: string) => {
  inputKey.value = searchValue;
  // console.log("use value", searchValue);
};
</script>
<style lang="less">
@import './index';

.footer{
  width: 100%;
  height: 48px;
  line-height: 48px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  background: #17375e;
  margin-top: 20px;
}
</style>
