import { createApp } from 'vue'
import App from './App.vue'
import "./registerServiceWorker";
import router, {setupRouter} from './router'
import { setupStore } from '@/store';
import {setupAntd, setupAssets} from '@/plugins'
import '@/styles/index.less'
// import * as directives from '@/directives'
import "amfe-flexible/index.js"
const app = createApp(App)
import vue3SeamlessScroll from "vue3-seamless-scroll";
import VueLazyload from 'vue-lazyload'

async function setupApp() {
  // 引入静态资源
  setupAntd(app);
  // 挂载pinia状态管理
  setupStore(app);
  // // Multilingual configuration
  // // Asynchronous case: language files may be obtained from the server side
  // await setupI18n(app);
  // 挂载路由
  await setupRouter(app);
  // 注册自定义指令
  // Object.keys(directives).forEach(key => {
  //   app.directive(key, directives[key])
  // })

  app.use(VueLazyload, {
    // 设置懒加载时的占位图
    loading: './assets/loading.gif',
    // 设置图片加载失败时的占位图
    error: 'path/to/error-image.png'
  })
  // @ts-ignore
  app.use(vue3SeamlessScroll).mount('#app');
}

setupApp();
