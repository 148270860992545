import {
  Modal,
  Menu,
  Input,
  Form,
  Checkbox,
  Radio,
  Col,
  Row,
  DatePicker,
  Tabs,
  Dropdown,
  Divider,
  Button,
  ConfigProvider,
  Layout,
  Popconfirm,
  Steps,
  Upload,
  List,
  Empty,
  Badge,
  Popover,
  Pagination,
  Breadcrumb,
  Card,
  Carousel
} from 'ant-design-vue';
import type { App } from 'vue';

// import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.variable.less';
import 'dayjs/locale/zh-cn';

export function setupAntd(app: App<Element>) {
  app
    .use(Form)
    .use(Input)
    .use(Modal)
    .use(Menu)
    .use(Checkbox)
    .use(Radio)
    .use(Col)
    .use(Tabs)
    .use(Dropdown)
    .use(Divider)
    .use(Row)
    .use(Button)
    .use(DatePicker)
    .use(ConfigProvider)
    .use(Layout)
    .use(Popconfirm)
    .use(Steps)
    .use(Upload)
    .use(List)
    .use(Empty)
    .use(Badge)
    .use(Popover)
    .use(Pagination)
    .use(Breadcrumb)
    .use(Card)
    .use(Carousel)
}
