<template>
  <a-config-provider :locale="locale">
    <router-view #="{ Component }">
      <component :is="Component" :key="route.fullPath" />
    </router-view>
  </a-config-provider>
</template>

<script setup>
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import locale from "ant-design-vue/es/locale/zh_CN";
import { useRoute } from "vue-router";

dayjs.locale("zh-cn");
const route = useRoute();

// export default {
//   setup() {
//     return {
//
//     };
//   }
//
// };

</script>

<style lang="less">
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*font-family: Microsoft YaHei, Wenquanyi Micro Hei, -apple-system, Helvetica Neue, Helvetica, Nimbus Sans L, Arial, Liberation Sans, Hiragino Sans GB, Source Han Sans CN, Source Han Sans SC, WenQuanYi Zen Hei, ST Heiti, SimHei, WenQuanYi Zen Hei Sharp, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,"\5FAE\8F6F\96C5\9ED1",Arial,sans-serif,Source Han Sans CN;*/
  /*font-size: 14px;*/
  height: 100%;
  //width: 998px;
  //margin: auto;
  overflow: auto;
}

</style>
