import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import type {  App } from 'vue';
import Layout from "@/layout/index.vue";
export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/layout/home'
  },
  {
    path: '/layout',
    component: Layout,
    redirect: '/layout/home',
    meta:{
      title:'Home',
      icon:'FileDoneOutlined'
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home/index.vue'),
        meta: {
          title: 'Home',
        }
      }
    ]
  },
  {
    path: '/layout',
    component: Layout,
    redirect: '/layout/products',
    meta:{
      title:'Products',
      icon:'FileDoneOutlined'
    },
    children: [
      {
        path: 'products',
        name: 'products',
        component: () => import('@/views/Products/index.vue'),
        meta: {
          title: 'Products',
        }
      }
    ]
  },
  {
    path: '/layout',
    component: Layout,
    redirect: '/layout/contact',
    meta:{
      title:'Contact Us',
      icon:'FormOutlined'
    },
    children: [
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/views/ContactUs/index.vue'),
        meta: {
          title: 'Contact Us',
        },
      }
    ]
  },
  {
    path: '/layout',
    component: Layout,
    redirect: '/layout/about',
    meta:{
      title:'About Us',
      icon:'SendOutlined'
    },
    children: [
      {
        path: 'about',
        name: 'about',
        component: () => import('@/views/AboutUs/about/index.vue'),
        meta: {
          title: 'About Us'
        },
      },
      {
        path: 'quality',
        name: 'quality',
        component: () => import('@/views/AboutUs/quality/index.vue'),
        meta: {
          title: 'Quality Policy'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export const setupRouter = async (app:App)=>{
  // createRouterGuards(router)
  app.use(router);

  // 路由准备就绪后挂载APP实例
  await router.isReady();
}

export default router
